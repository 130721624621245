<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <ClaimantsList
            :headers="headersClaimants"
            :items="itemsClaimants"
            @initialize="initialize"
            :search_tool="true"
          ></ClaimantsList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ClaimantsList from "@/views/pages/claimants/ClaimantsList.vue";
import genericService from "./../../../services/generic.service";

export default {
  components: {
    ClaimantsList
  },

  data: () => ({
    uri:"claimants",
    headersClaimants: [
      { text: "ID", value: "id" },
      { text: "Work Order", value: "work_order" },
      { text: "Company Name", value: "company_label" },
      { text: "Claim", value: "claim" },
      { text: "Amount", value: "amount" },
      { text: "Lou", value: "lou", sortable: true },
      { text: "Actions", value: "actions" }
    ],
    itemsClaimants: []
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      genericService.getRecordList('/'+this.uri, function(records){
          _self.itemsClaimants = records
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Claimants" }]);
  }
};
</script>
