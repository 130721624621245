<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    show-expand
    item-key="id"
    sort-by="id"
    sort-desc
    class="elevation-1"
    @current-items="currentItems"
    :loading="loading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Claimants List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <!-- Filtros -->
        <v-dialog v-if="search_tool" v-model="dialogFilters" max-width="400px">
          <template v-slot:activator="{ on }">
            <!--<v-btn
              class="ml-4"
              color="blue-grey"
              fab
              small
              dark
              v-on="on"
              @click="dialogFilters = true"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>-->
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Advanced Search</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      :items="filters"
                      v-model="filters.text"
                      label="Filters"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-menu
                      ref="rangeCalendar"
                      v-model="rangeCalendar"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-x
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Dates"
                          hint="Enter a date range range. Separate with '~'"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="rangeDates" no-title range>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="rangeDates = []">Clear</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rangeCalendar.save(rangeDates);"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-radio-group v-model="radios">
                      <span>
                        Replaced:
                        <v-btn
                          icon
                          color="gray"
                          class="ml-1"
                          @click="radios = null"
                        >
                          <v-icon>mdi-cached</v-icon>
                        </v-btn>
                      </span>
                      <v-radio value="Yes">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">Yes</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="No">
                        <template v-slot:label>
                          <div>
                            <strong class="red--text">No</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogFilters = false"
                >Close</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="filterItems(filters.text)"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Fin Filtros -->

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="750px">
          <template v-slot:activator="{ on }">
            <v-btn 
              color="primary"
              dark
              class="mb-2"
              v-on="on"
              @click="formNewItem()"
            >New Claimants</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row v-if="formNewtItem" class="form-first-field">
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="folder"
                      placeholder="Select Work Order"
                      :items="workOrdersList"
                      item-text="id"
                      item-value="id"
                      label="Work Order"
                      v-model="current_wo_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-switch
                      v-model="editedItem.lou"
                      color="info"
                      hide-details
                    >
                      <template v-slot:label>
                        <v-chip
                          color="green"
                          dark
                          v-if="editedItem.lou"
                          class="ml-2"
                          >LOU</v-chip
                        >
                        <v-chip color="red" dark v-else class="ml-2"
                          >LOU</v-chip
                        >
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.company_label"
                      label="Company Name"
                      prepend-icon="business"
                    ></v-text-field>
                    <!--<v-autocomplete
                      prepend-icon="business"
                      placeholder="Select Company"
                      :search-input.sync="editedItem.company_label"
                      :items="companyList"
                      item-text="name"
                      item-value="id"
                      label=""
                      v-model="editedItem."
                    ></v-autocomplete>-->
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.amount"
                      type="number"
                      prefix="$"
                      label="Amount"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.claim"
                      label="Claim"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="save(items)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.lou="{ item }">
      <v-chip color="green" dark v-if="item.lou ==  1">LOU</v-chip>
      <v-chip color="red" dark v-else>LOU</v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item, 'dialog')"
        v-b-tooltip.hover
        title="Edit"
        >mdi-pencil</v-icon
      >
      <v-icon
        small
        @click="deleteItem(item, items)"
        v-b-tooltip.hover
        title="Delete"
        >mdi-delete</v-icon
      >
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
    <template v-slot:item.amount="{ item }">
      {{ formatAmount(item.amount) }}
    </template>
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length + 1">
        <div class="row justify-center">
          <div class="col-md-11">
            <div class="row mt-3">
              <div class="col-4">
                <v-container fluid>
                  <h5>Submitted:</h5>
                  <p>
                    {{ item.submitted }}
                  </p>
                </v-container>
              </div>
              <div class="col-4">
                <v-container fluid>
                  <h5>Replaced:</h5>
                  <p>
                    {{ item.replaced }}
                  </p>
                </v-container>
              </div>
              <div class="col-4">
                <v-container fluid>
                  <h5>Cancelled:</h5>
                  <p>
                    {{ item.cancelled }}
                  </p>
                </v-container>
              </div>
            </div>
          </div>
        </div>
      </td>
    </template>
    <template slot="footer">
      <table>
        <tr
          class="border border-right-0 border-left-0 border-bottom-0 text-center"
        >
          <td colspan>
            <v-chip color="lighten-1 mr-4">
              Items: &nbsp;
              <strong class="ml-2">{{ itemsFiltered }}</strong>
            </v-chip>
            <v-chip color="lighten-1">
              Amount: &nbsp;
              <strong class="ml-2">{{ totalAmount }}</strong>
            </v-chip>
          </td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri:"claimants",
    workOrdersList: [],
    singleExpand: false,
    expanded: [],
    formNewtItem: true,
    popDatepick1: false,
    popDatepick2: false,
    popDatepick3: false,
    dialog: false,
    search: "",
    radios: "",
    editedIndex: -1,
    totalAmount: 0,
    editedItem: {
      work_order: null,
      company_name: "",
      company_label: "",
      claim: "",
      lou: true,
      amount: null
    },
    defaultItem: {
      work_order: null,
      company_name: "",
      company_label: "",
      claim: "",
      lou: true,
      amount: null
    },
    switch1: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "Work Order", value: 0 },
      { text: "Company Name", value: 1 },
      { text: "Claim", value: 2 },
      { text: "Amount", value: 5 }
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0,
    companyList:[]
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Claimants" : "Edit Claimants";
    },
    formTitle2() {
      return this.editedIndex === -1 ? "Replace Claimants" : "Replace Claimants";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },
    ...mapGetters([ 'currentUser' ])
  },

  props: ["headers", "items", "search_tool", "current_wo_id"],

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    editItem(item, dialog) {
      /* Listado de Dialogs */
      if (dialog == "dialog") {
        this.formNewtItem = false;
        this.dialog = true;
      }
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item, list) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = list.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save(items) {
      var _self = this;

      if (this.editedIndex > -1) {
        Object.assign(items[this.editedIndex], this.editedItem);
        genericService.updateRecord("/"+this.uri+"/" + this.editedItem.id, this.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      } else {
        this.editedItem.work_order = this.current_wo_id;
        items.push(this.editedItem);
        genericService.createRecord("/"+this.uri, this.editedItem, function(rta){
          if(rta != undefined) {
            Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
            _self.editedItem.id = rta.id;
          }
        });
      }
      this.currentItems(items);
      this.close();
    },

    reset() {
      this.$emit("initialize");
    },

    toNumber(nr) {
      nr = parseFloat(nr);
      return nr;
    },

    formatCurrencyAmount(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      });

      return formatter.format(amount);
    },

    formatAmount(nr) {
      if((nr == undefined) || (nr == null) || (isNaN(nr))) {
        return 0;
      }
      return nr;
    },


    /* Filtros & Loading */
    currentItems(items) {
      this.loading = true;
      setTimeout(() => {
        this.itemsFiltered = items.length;

        var sum = items.reduce((a, item) => a + this.formatAmount(parseFloat(item.amount)), 0);
        this.totalAmount = this.formatCurrencyAmount(sum);


        this.loading = false;
      }, 300);
    },
    filterItems(itemFiltered) {
      if (itemFiltered == 99 || itemFiltered == null) {
        this.headers.forEach(header => (header.filterable = true));
      } else {
        this.headers.forEach(header => (header.filterable = false));
        this.headers[itemFiltered].filterable = true;
      }
      this.dialogFilters = false;
      return this.headers;
    },
    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },
    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },
    formNewItem(){
      this.formNewtItem = true;
      setTimeout(() => {
        this.focusField(this.$refs.formFirstField);
      }, 200)
    }
  },
  mounted() {
    var _self = this;

    if(this.search_tool == true){
      this.focusField(this.$refs.search);
    }

    genericService.getRecordList('/workorders/', function(records){
        _self.workOrdersList = records.reverse();
    });
  }
};
</script>
